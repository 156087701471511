"use client";
import { createContext, useContext, useState } from "react";

interface Config {
  cdnUrl: string;
  cdnDisabled: boolean;
  ocrQueueName: string;
}

const ConfigContext = createContext<Config | null>(null);

export function ConfigProvider({
  children,
  initialConfig,
}: {
  children: React.ReactNode;
  initialConfig: Config;
}) {
  const [config] = useState(initialConfig);

  return (
    <ConfigContext.Provider value={{ ...config }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within ConfigProvider");
  }
  return context;
}
